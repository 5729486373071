import React from "react";
import "./../css/announcement.css"

const Announcement = () => {
  return (
    <a
      href="http://go.brettbejcek.com/latest-update"
      target="_blank"
      rel="noopener noreferrer"
      style={{ textDecoration: "none"}}
    >
      <div className="promo-card" style={{ marginTop:"2rem", border:"#e9e9e9 5px solid"}}>
        <div className="promo-icon">👋 </div>
    
        <div className="promo-text">
          <p className="promo-title" style={{color:`#7d4aa3`}}>April 2025 Update</p>
          <p className="promo-subtitle">More on who I am and who I am becoming.</p>
        </div>
    
        <div className="promo-arrow">➝</div>
      </div>
    </a>
  );
};

export default Announcement;
